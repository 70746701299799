/**
* Utility classes
*/

/**
* Wordpress image alignment
*/
.alignright {
  float: none;

  @include breakpoint(large) {
    float: right;
  }
}

.alignleft {
  float: none;

  @include breakpoint(large) {
    float: left;
  }
}

.aligncenter {
  margin: 0 auto;
  max-width: 100%;
}
