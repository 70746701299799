// Menu

.menu {
  > li {
    > a {
      padding: rem-calc(21);
    }
  }

  a {
    color: var(--text-color-inverted);

    &:hover,
    &:focus {
      color: var(--link-hover-color);
      outline: none;
    }
  }

  @include breakpoint(large) {
    .primary-nav--white &,
    .primary-nav--light & {
      a {
        color: var(--black-color);

        &:hover,
        &:focus {
          color: var(--link-hover-color);
        }
      }
    }

    &.submenu {
      .primary-nav--white &,
      .primary-nav--light & {
        a {
          color: var(--black-color);

          &:hover,
          &:focus {
            color: var(--link-hover-color);
          }
        }
      }
    }
  }
}
