/*!
Theme Name: LTW Site Builder
Theme URI: http://www.leasethewebsite.com
Author: Lease the Web
Author URI: http://www.leasethewebsite.com
*/

// General Settings
// =====================
@import "variables";

// Foundation
// ======================
@import "foundation-sites/scss/foundation";

// Initialize Foundation components
@include foundation-callout;
@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-menu;
@include off-canvas-basics;
@include foundation-title-bar;
@include foundation-visibility-classes;
@include foundation-forms;
@include foundation-button;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-drilldown-menu;
@include foundation-media-object;

// Other Vendor Styles
// ======================
@import "vendor/slick";
@import "vendor/magnific-popup";

// Site Styles
// ======================
@import "base/fonts";
@import "base/buttons";
@import "base/icons";
@import "base/layout";
@import "base/type";
@import "components/article-hero";
@import "components/footer";
@import "components/forms";
@import "components/hamburger";
@import "components/menu";
@import "components/module";
@import "components/primary-nav";
@import "components/slick";
@import "components/title-bar";

// Modules
// ======================
@import "modules/accordion-list";
@import "modules/content-card";
@import "modules/image-block";
@import "modules/hero";
@import "modules/reviews";
@import "modules/rotator";
@import "modules/table-block";
@import "modules/text-block";
@import "modules/text-image-block";
@import "modules/three-up-content";
@import "modules/two-up-cta";

// Utility
// ======================
@import "utility";
