// Module Text Block

.text-block {
  &--post {
    margin: rem-calc(72) 0;
    padding: 0;
  }

  .columns {
    + .columns {
      margin-top: rem-calc(50);

      @include breakpoint(large) {
        margin-top: 0;
      }
    }
  }

  &--stacked {
    .columns {
      + .columns {
        margin-top: rem-calc(50);
      }
    }
  }
}

.text-block__title {
  @extend h4;

  margin: 0 0 rem-calc(25);
}

.text-block__content {
  display: block;
  margin: 0 auto;

  p:last-child {
    margin-bottom: 0;
  }
}
