// Accordion List

.accordion-list__title {
  color: var(--primary-color);
  margin-bottom: 42px;

  .module--primary &,
  .module--secondary & {
    color: inherit;
  }
}

.accordion-list__item {
  border-top: 2px solid $lighter-gray;
  padding: 32px 0 28px;
  position: relative;

  &:last-child {
    border-bottom: 2px solid $lighter-gray;
  }

  .module--primary &,
  .module--secondary &,
  .module--dark & {
    border-color: inherit;
  }
}

.accordion-list__item-toggle {
  background-color: transparent;
  border: 0;
  color: $dark-gray;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 1px;
  line-height: 1;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 20px 0 20px 30px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  text-transform: none;
  top: 50%;
  transform: translateY(-50%);

  .module--primary &,
  .module--secondary &,
  .module--dark & {
    color: inherit;
  }

  .icon-plus,
  .icon-minus {
    display: block;
    opacity: 1;
    position: absolute;
    right: 0;
    transform: translateY(-50%);
    transition: opacity 0.1s;
  }

  .icon-minus {
    opacity: 0;
  }

  .accordion-active & {
    .icon-plus {
      opacity: 0;
    }

    .icon-minus {
      opacity: 1;
    }
  }
}

.accordion-list__item-title {
  cursor: pointer;
  margin: 0;
  padding-right: 30px;
  position: relative;

  @include primary-font;
}

.accordion-list__item-content {
  overflow: hidden;
  transition: height ease 0.5s;

  &[aria-hidden=true] {
    height: 0 !important;
  }
}

.accordion-list__item-body {
  margin: 0;
  max-width: 885px;
  overflow: hidden;
  padding: 30px 0 3px;

  p:last-child {
    margin: 0;
  }
}

.accordion-list__buttons {
  margin: 20px -3px 0;

  .button {
    margin: 10px 3px 0;

    .module--primary &,
    .module--secondary & {
      @extend .button--inverse;

      + .button {
        @extend .button--inverse-outline;
      }
    }
  }
}
