// Module Two Up CTA

.two-up-cta {
  text-align: center;
  width: 100%;

  @include breakpoint(medium) {
    display: flex;
  }
}

.two-up-cta__block {
  padding: rem-calc($module-spacing-mobile $gutter-mobile);

  @include breakpoint(medium) {
    padding: rem-calc($module-spacing-desktop $gutter-desktop);
    width: 50%;
  }

  &--dark {
    background: var(--black-color);
    color: var(--text-color-inverted);
  }

  &--light {
    background: var(--light-color);
  }

  &--primary {
    background: var(--primary-color);
    color: var(--text-color-inverted);
  }

  &--secondary {
    background: var(--secondary-color);
    color: var(--text-color-inverted);
  }

  &--primary,
  &--secondary {
    .button {
      @extend .button--inverse;
    }
  }
}

.two-up-cta__content {
  margin: 0 auto;

  @include breakpoint(medium) {
    max-width: 100%;
    width: rem-calc($row-width/2);
  }
}

.two-up-cta__title {
  margin: 0;
}

.two-up-cta__body {
  font-size: rem-calc(24);
  margin-top: rem-calc(30);

  p:last-child {
    margin-bottom: 0;
  }
}

.two-up-cta__button {
  margin-top: rem-calc(42);
}
