.button {
  background: var(--primary-color);
  border-radius: var(--button-border-radius);
  border-width: 2px;
  color: var(--text-color-inverted);
  font-size: 14px;
  letter-spacing: 0.8px;
  margin: 0;
  min-width: 160px;
  outline: none;
  padding: 12px 25px;
  text-transform: uppercase;
  transition: all 0.25s ease-out;

  @include primary-font-bold();

  @include breakpoint(medium) {
    font-size: 15px;
    min-width: 200px;
    padding: 15px 25px;
  }

  &:hover,
  &:focus {
    background: var(--primary-hover-color);
  }

  .icon {
    height: 18px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.button__text {
  .button--has-icon & {
    padding-left: 28px;
    position: relative;
  }
}

.button--outline {
  background: transparent;
  border-color: var(--primary-color);
  color: var(--primary-color);

  &:hover,
  &:focus {
    background: var(--primary-color);
  }
}

.button--dark {
  background: var(--black-color);
  border-color: var(--black-color);
  color: var(--text-color-inverted);

  &:hover,
  &:focus {
    background: var(--primary-color);
    border-color: var(--primary-color);
  }
}

.button--inverse {
  background: var(--text-color-inverted);
  border-color: var(--text-color-inverted);
  color: var(--text-color-default);

  &:hover,
  &:focus {
    background: transparent;
    color: var(--text-color-inverted);
  }
}

.button--inverse-outline {
  background: transparent;
  border-color: var(--text-color-inverted);
  color: var(--text-color-inverted);

  &:hover,
  &:focus {
    background: var(--text-color-inverted);
    color: var(--text-color-default);
  }
}

.button--small {
  font-size: 14px;
  min-width: 160px;
  padding: 12px 25px;
}

.button--a {
  background: var(--button-a-color);
  border-color: var(--button-a-color);
  color: var(--text-color-inverted);

  &:hover,
  &:focus {
    background: var(--button-a-hover-color);
    border-color: var(--button-a-hover-color);
  }
}

.button--b {
  background: var(--button-b-color);
  border-color: var(--button-b-color);
  color: var(--text-color-inverted);

  &:hover,
  &:focus {
    background: var(--button-b-hover-color);
    border-color: var(--button-b-hover-color);
  }
}

.button--c {
  background: var(--button-c-color);
  border-color: var(--button-c-color);
  color: var(--text-color-inverted);

  &:hover,
  &:focus {
    background: var(--button-c-hover-color);
    border-color: var(--button-c-hover-color);
  }
}
