// Table Block

.table-block__title {
  margin: 0 0 rem-calc(42);
}

.table-block__table-wrapper {
  margin: 0 -#{$gutter-mobile}px;
  position: relative;

  @include breakpoint(medium) {
    margin: 0;
  }

  &::after {
    background-image: linear-gradient(to left, $white 20%, rgba(255, 255, 255, 0.4));
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: rem-calc(35);

    @include breakpoint(medium) {
      display: none;
    }
  }
}

.table-block__table {
  background: $white;
  border: 1px solid $light-gray;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  @include breakpoint(medium) {
    border-radius: rem-calc(10);
    overflow: initial;
  }

  .module--dark &,
  .module--primary &,
  .module--secondary & {
    border: 0;
  }

  table {
    border-collapse: collapse;
    color: var(--text-color-default);
    text-align: center;
    width: 100%;
  }

  th {
    white-space: nowrap;

    @include primary-font-bold();

    @include breakpoint(medium) {
      white-space: normal;
    }
  }

  th,
  td {
    border-bottom: 1px solid $light-gray;
    padding: rem-calc(18);
  }

  tr:last-child {
    td {
      border: 0;
    }
  }
}
