// Module Image

.image-block {
  display: block;
  margin: rem-calc(72) 0;

  &--full-width {
    background: var(--black-color) no-repeat center center;
    background-size: cover;
    height: rem-calc(250);
    margin: 0;

    @include breakpoint(medium) {
      height: rem-calc(400);
    }

    @include breakpoint(large) {
      height: rem-calc(600);
    }
  }
}

.image-block__image {
  display: block;
  margin: 0 auto;
  max-height: rem-calc(300);
}
