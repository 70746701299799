// Module Hero

.hero {
  display: flex;
  position: relative;
  text-align: center;
  width: 100%;

  &--small {
    @include breakpoint(medium) {
      min-height: 600px;
    }
  }

  &--large {
    @include breakpoint(medium) {
      min-height: 600px;
    }
  }

  &--has-image {
    background: var(--black-color) no-repeat center center;
    background-size: cover;
    color: var(--text-color-inverted);

    &.hero--text-default {
      color: var(--text-color-default);
    }
  }
}

.hero__overlay {
  bottom: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;

  &--black {
    background: $black-overlay;
  }

  &--primary {
    background: var(--primary-color);
  }

  &--secondary {
    background: var(--secondary-color);
  }
}

.hero__content {
  margin: auto;
  max-width: 780px;
  position: relative;
}

.hero__subtitle {
  font-size: 16px;
  margin: 18px 0 0;
}

.hero__button {
  margin-top: 30px;
}
