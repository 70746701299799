// Primary Nav
//
// Primary navigation for the site
// Uses the following Foundation components:
// - Dropdown
// - Drilldown
// - Off-canvas
// - Responsive menu
// See Foundation documentation for details

.primary-nav {
  @include off-canvas-base;
  @include off-canvas-position(right);

  background: var(--black-color);
  font-size: 14px;
  letter-spacing: 1px;
  padding: 20px;
  text-transform: uppercase;

  @include breakpoint(large) {
    @include off-canvas-reveal(right);

    align-items: center;
    display: flex;
    justify-content: space-between;
    max-height: none;
    overflow: inherit;
    padding: 12px 16px 12px 24px;
    position: static;
    width: 100%;

    @include primary-font-medium();

    &--white {
      background: $white;
    }

    &--light {
      background: var(--light-color);
    }

    ~ .off-canvas-content {
      box-shadow: none;
      margin-right: 0;
    }

    .back {
      display: none !important;
    }

    &.is-open ~ .off-canvas-content {
      transform: translateX(0);
    }

    > .menu {
      margin-left: auto;
    }
  }

  ~ .off-canvas-content {
    transition: transform 0.5s ease;
  }
}

.primary-nav__logo {
  height: 65px;
  line-height: 1;
  margin: 0;

  a,
  img {
    display: block;
    height: 100%;
  }
}

.js-off-canvas-overlay {
  background: none;

  @include breakpoint(large) {
    display: none !important;
  }
}

.is-dropdown-submenu {
  background: var(--black-color);
  border: 0;

  .primary-nav--white & {
    background: $white;
  }

  .primary-nav--light & {
    background: var(--light-color);
  }

  &.menu {
    > li {
      > a {
        padding: 18px;
      }
    }
  }
}

.dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
  display: none;
}

.is-drilldown-submenu-parent > a::after {
  border-color: transparent transparent transparent var(--primary-color);
}

.js-drilldown-back > a::before {
  border-color: transparent var(--primary-color) transparent transparent;
  margin-right: 15px;
  margin-top: -4px;
}

.is-drilldown-submenu {
  background: var(--black-color);
}
