// Three Up Content
.three-up-content {
  text-align: center;
}

.three-up-content__title {
  margin-bottom: rem-calc(24);

  @include breakpoint(medium) {
    margin-bottom: rem-calc(42);
  }
}

.three-up-content__blocks {
  @include breakpoint(large) {
    display: flex;
    margin: 0 rem-calc(-25);
  }
}

.three-up-content__block {
  @include breakpoint(medium) {
    margin: 0 auto;
    width: 50%;
  }

  @include breakpoint(large) {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: rem-calc(0 25);
    width: 1/3 * 100%;
  }

  + .three-up-content__block {
    margin-top: rem-calc(30);

    @include breakpoint(medium) {
      margin-top: rem-calc(50);
    }

    @include breakpoint(large) {
      margin-top: 0;
    }
  }
}

.three-up-content__block__content {
  margin-bottom: auto;
}

.three-up-content__block__icon {
  display: block;
  height: rem-calc(42);
  margin: 0 auto rem-calc(12);
  width: rem-calc(42);

  @include breakpoint(medium) {
    margin-bottom: rem-calc(24);
  }

  img {
    display: block;
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
  }
}

.three-up-content__block__title {
  margin: 0;
}

.three-up-content__block__body {
  margin-top: rem-calc(12);

  @include breakpoint(medium) {
    margin-bottom: rem-calc(24);
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.three-up-content__block__button {
  margin-top: rem-calc(20);

  @include breakpoint(medium) {
    margin-top: rem-calc(25);
  }
}

.three-up-content__button {
  margin-top: rem-calc(30);

  @include breakpoint(medium) {
    margin-top: rem-calc(50);
  }
}
