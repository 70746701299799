// hamburger
.hamburger {
  border: none;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  font-size: 0;
  height: 15px;
  margin: 0 0 0 auto;
  padding: 0;
  position: relative;
  text-indent: -9999px;
  top: 1px;
  width: 30px;

  &:focus {
    outline: none;
  }

  a {
    background-color: $white;
    display: block;
    font-size: 0;
    height: 3px;
    left: 0;
    line-height: 0;
    position: absolute;
    right: 0;
    top: 6px;
    transition: background-color 0.3s;
    width: 100%;

    .title-bar--white &,
    .title-bar--light & {
      background-color: var(--black-color);
    }

    &::before,
    &::after {
      background-color: $white;
      content: "";
      display: block;
      height: 3px;
      left: 0;
      position: absolute;
      transition-delay: 0.3s, 0s, 0s;
      transition-duration: 0.3s, 0.3s, 0.3s;
      width: 100%;

      .title-bar--white &,
      .title-bar--light & {
        background-color: var(--black-color);
      }
    }

    &::before {
      top: -8px;
      transition-property: top, transform, background-color;
    }

    &::after {
      bottom: -8px;
      transition-property: bottom, transform, background-color;
    }
  }

  &[aria-expanded="true"] {
    a {
      background-color: transparent;

      &::before,
      &::after {
        transition-delay: 0s, 0.3s;
      }

      &::before {
        top: 0;
        transform: rotate(45deg);
      }

      &::after {
        bottom: 0;
        transform: rotate(-45deg);
      }
    }
  }
}
