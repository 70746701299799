// Type

body {
  color: var(--text-color-default);
  font-size: 16px;

  @include primary-font;
}

h1 {
  font-size: 36px;
  line-height: 1.15;

  @include secondary-font-bold;

  @include breakpoint(medium) {
    font-size: 60px;
  }
}

h2 {
  font-size: 30px;
  letter-spacing: 0.3px;
  line-height: 1.2;

  @include secondary-font-bold;

  @include breakpoint(medium) {
    font-size: 36px;
  }
}

h3 {
  font-size: 24px;
  line-height: 1.2;

  @include secondary-font-bold;

  @include breakpoint(medium) {
    font-size: 30px;
  }
}

h4 {
  font-size: 20px;
  letter-spacing: 0.2px;

  @include secondary-font-bold;

  @include breakpoint(medium) {
    font-size: 24px;
  }
}

h5 {
  font-size: 18px;

  @include primary-font-medium;

  @include breakpoint(medium) {
    font-size: 20px;
  }
}

a {
  color: var(--link-color);
  transition: all $transition;

  &:hover,
  &:focus {
    color: var(--link-hover-color);
  }
}

p {
  letter-spacing: 0.2px;
  margin-bottom: 20px;
}
