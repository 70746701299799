// Reviews

.review {
  text-align: center;
}

.review__stars {
  color: var(--primary-color);
  margin-bottom: 30px;

  .module--primary &,
  .module--secondary & {
    color: inherit;
  }

  .icon-star {
    color: transparent;
    margin: 0 2.5px;
    stroke: currentColor;
  }
}

.review__stars--1 {
  .icon-star:first-child {
    color: inherit;
  }
}

.review__stars--2 {
  .icon-star:nth-child(-n+2) {
    color: inherit;
  }
}

.review__stars--3 {
  .icon-star:nth-child(-n+3) {
    color: inherit;
  }
}

.review__stars--4 {
  .icon-star:nth-child(-n+4) {
    color: inherit;
  }
}

.review__stars--5 {
  .icon-star {
    color: inherit;
  }
}

.review__quote {
  @extend h4;

  @include secondary-font;

  margin-bottom: 18px;
}

.review__attribution {
  @include secondary-font-bold;

  font-size: 18px;
  line-height: 1.4;
  margin: 0;
}
