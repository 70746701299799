// Title Bar
.title-bar {
  align-items: center;
  background: var(--black-color);
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;
  width: 100%;

  &--white {
    background: $white;
  }

  &--light {
    background: var(--light-color);
  }

  &::after,
  &::before {
    display: none;
  }
}

.title-bar-title {
  height: 65px;
  line-height: 1;
  margin: 0;

  a,
  img {
    display: block;
    height: 100%;
  }
}
