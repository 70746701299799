// Fonts

@mixin primary-font() {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: var(--primary-font-regular-weight);
}

@mixin primary-font-medium() {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: var(--primary-font-medium-weight);
}

@mixin primary-font-bold() {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: var(--primary-font-bold-weight);
}

@mixin secondary-font() {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: var(--secondary-font-regular-weight);
}

@mixin secondary-font-bold() {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: var(--secondary-font-bold-weight);
}
