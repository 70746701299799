// Footer

.footer {
  background: var(--black-color);

  &--white {
    background: $white;
  }

  &--light {
    background: var(--light-color);
  }
}

.footer__content {
  padding: 50px 0;
  text-align: center;
  width: 100%;

  @include breakpoint(medium) {
    display: flex;
    padding: 110px 0;
    text-align: left;
  }
}

.footer__menu-container {
  @include breakpoint(medium) {
    display: flex;
  }
}

.footer__logo {
  display: block;
  margin: 0 auto 40px;
  width: 75px;

  img {
    width: 100%;
  }

  @include breakpoint(medium) {
    align-self: center;
    margin: 0 60px 0 0;
  }

  &:focus {
    outline: none;
  }
}

.footer__menu {
  display: block;
  font-size: 14px;
  letter-spacing: 0.7px;
  line-height: 1.4;
  list-style: none;
  margin: 0 0 20px;
  padding: 0;
  text-transform: uppercase;

  @include primary-font-bold();

  &--secondary {
    @include primary-font();
  }

  @include breakpoint(medium) {
    margin: 0 27px 0 0;
  }

  li {
    padding: 0 0 15px;

    @include breakpoint(medium) {
      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  a {
    color: var(--text-color-inverted);

    &:hover,
    &:focus {
      color: var(--link-hover-color);
    }

    .footer--white &,
    .footer--light & {
      color: var(--text-color-default);

      &:hover,
      &:focus {
        color: var(--link-hover-color);
      }
    }
  }
}

.footer__social {
  margin-top: 30px;

  @include breakpoint(medium) {
    align-items: center;
    display: flex;
    margin: 0 0 0 auto;
  }

  .icon-facebook {
    height: 33px;
    width: 18px;
  }

  .icon-twitter {
    height: 25px;
    width: 31px;
  }

  .icon-instagram {
    height: 30px;
    width: 30px;
  }
}

.footer__social__link {
  color: var(--primary-color);
  display: inline-block;
  margin: 0 15px;

  @include breakpoint(medium) {
    margin: 0 0 0 25px;
  }

  &:hover,
  &:focus {
    color: var(--primary-hover-color);
    outline: none;
  }
}

.footer__bottom {
  border-top: 1px solid $gray;
  color: $gray;
  font-size: 14px;
  padding: 13px 0 35px;
  text-align: center;

  a {
    color: inherit;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: var(--link-hover-color);
    }
  }

  &--attribution {
    @include breakpoint(medium) {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}

.footer__attribution {
  display: none;
  margin: 0 0 3px;

  @include breakpoint(medium) {
    margin: 0;
  }

  .footer__bottom--attribution & {
    display: block;
  }
}

.footer__copyright {
  margin: 0 auto;
}
