// Module

.module {
  padding: rem-calc($module-spacing-mobile 0);

  @include breakpoint(medium) {
    padding: rem-calc($module-spacing-desktop 0);
  }

  &--flush {
    padding-bottom: 30px;

    @include breakpoint(medium) {
      padding-bottom: 50px;
    }
  }

  &--dark {
    background: var(--black-color);
    color: var(--text-color-inverted);

    + .module--dark {
      padding-top: 0;
    }
  }

  &--light {
    background: var(--light-color);

    + .module--light {
      padding-top: 0;
    }
  }

  &--primary {
    background: var(--primary-color);
    color: var(--text-color-inverted);

    + .module--primary {
      padding-top: 0;
    }
  }

  &--secondary {
    background: var(--secondary-color);
    color: var(--text-color-inverted);

    + .module--secondary {
      padding-top: 0;
    }
  }

  &--none {
    + .module--none {
      padding-top: 0;
    }
  }
}
